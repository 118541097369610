import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { tab_State_Reducer } from "rootExports/TabPersistReducer";

export interface TabProps {
    Component: JSX.Element;
    Title: JSX.Element | string;
    Icon?: IconDefinition;
    RenderOnlyIfActive?: boolean,
    AmountIndicator: number | undefined,
    applyCss?: boolean
}
export interface TabbedContentProps {
    EnableJump: boolean
    Tabs: TabProps[],
    SetSelectedSegment?: any
}

interface TabbedContentPropsNew {
    EnableJump: boolean
    Tabs: TabProps[],
    SetSelectedSegment?: any
    componentName:string;
    tabIndexToggle?:number;
}

export const 
TabbedContent: React.FC<TabbedContentPropsNew> = ({Tabs, SetSelectedSegment,componentName,tabIndexToggle}) => {


    const tabIndex = useSelector((state: any) => state.TabStateSession.value);

    const [activeTab, setActiveTab] = useState(tabIndexToggle !=undefined ? tabIndexToggle: tabIndex.index);

    const toggle = (tab: number) => {
        if (activeTab !== tab) setActiveTab(tab); SetSelectedSegment(tab);
    }

    const dispatch = useDispatch();

    useEffect(() => {
        if(tabIndex.component == componentName){
            dispatch(tab_State_Reducer({index:activeTab,component:componentName}))
        }
    }, [activeTab])


    useEffect(() => {
        if((tabIndex.component != componentName) && (tabIndexToggle ==undefined)){
            dispatch(tab_State_Reducer({index:0,component:componentName}))
        }
    }, [])

    useEffect(() => {
        if((tabIndex.component != componentName) && (tabIndexToggle ==undefined)){
            setActiveTab(0)
        }
        if(tabIndex.component == componentName){
            setActiveTab(tabIndex.index)
        }
    }, [tabIndex.component])


    return <Fragment>
        <Nav tabs pills>
            {Tabs.map((tab, index) =>
                <NavItem key={"nav_" + index} >
                    <NavLink
                        className={`${activeTab === index ? "active" : ""} clickme`} style={{}}
                        onClick={() => { toggle(index); }}>
                        {tab.Icon &&
                            <Fragment>
                                <FontAwesomeIcon icon={tab.Icon} />
                                {` `}
                            </Fragment>
                        }
                        <span className="p-1">
                            {tab.Title}{``}
                        </span>
                        <span>
                            {tab.AmountIndicator && <span style={{ fontSize: "0.7em" }} className="align-top badge rounded-pill text-bg-dark">{tab.AmountIndicator}</span>}
                        </span>
                    </NavLink>
                </NavItem>
            )}
        </Nav>
        <TabContent activeTab={activeTab}>
            {Tabs.map((tab, index) =>
                <TabPane tabId={index} key={"tabpen_" + index} className={tab.applyCss == true ? "manageElementCancelActive" : ''} >
                    {((tab.RenderOnlyIfActive !== undefined && tab.RenderOnlyIfActive === true && activeTab === index)
                        || (tab.RenderOnlyIfActive === undefined) || (tab.RenderOnlyIfActive !== undefined && tab.RenderOnlyIfActive === false)) &&
                        <div className="mt-2">
                            {tab.Component}
                        </div>
                    }
                </TabPane>
            )}

        </TabContent>
    </Fragment>
}