import { ICabinClassState } from "components/FareSearch/types/FareSearchTypes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";

interface CabinClassesProps {
  CabinClasses: ICabinClassState,
  UpdateCabinClasses: (cabinClasses: ICabinClassState) => void;
  ClassName?: string
  disabled: boolean
  segmentIndex: any
}

const CabinClassInput: React.FC<CabinClassesProps> = props => {

  const otherSettings: string = useSelector((state: State) => state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ? state.Session.FrontendSettings.otherSettingsAsKeyValueText == "menuPosition=L\nshowFareHeader=On\nshowSmartCalender=None/n[]/nfalse/nfalse\nPreselectAllCabins" ?
  'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None' 
   : 
   'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None' : state.Session.FrontendSettings.otherSettingsAsKeyValueText);
  const displayCabin = otherSettings.split('\n');

  const cabin = displayCabin[3] == undefined ? '{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}' : displayCabin[3];
  var cabinClass: any;
  if (cabin.includes('preSelect')) {
    cabinClass = JSON.parse(displayCabin[3] == undefined ? '{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}' : displayCabin[3]);
  } else {
    cabinClass = JSON.parse('{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}');
  }

  const cabinClasses = { ...props.CabinClasses };
  
  const [BoolTrigger, setBoolTrigger] = useState(false)
 
  useEffect(() => {
    const { Economy, Premium, Business, First } = cabinClass;
  
    // Create a new state object based on props and current cabin class selections
    const newState: ICabinClassState = {
      ...props.CabinClasses,
      Economy: Economy.preSelect,
      PremiumEconomy: Premium.preSelect,
      Business: Business.preSelect,
      First: First.preSelect,
    };
    // Update the cabin classes with the new state
    props.UpdateCabinClasses(newState);
    setBoolTrigger(true)
  }, [BoolTrigger]);
  


  function OnToggleEconomy() {
    if (!props.disabled) {
      let newState: ICabinClassState = { ...cabinClasses };
      newState.Economy = !newState.Economy;
      props.UpdateCabinClasses(newState);
    }
  }

  function OnTogglePremiumEconomy() {
    if (!props.disabled) {
      let newState: ICabinClassState = { ...cabinClasses };
      newState.PremiumEconomy = !newState.PremiumEconomy;
      props.UpdateCabinClasses(newState);
    }
  }

  function OnToggleBusiness() {
    if (!props.disabled) {
      let newState: ICabinClassState = { ...cabinClasses };
      newState.Business = !newState.Business;
      props.UpdateCabinClasses(newState);
    }
  }

  function OnToggleFirst() {
    if (!props.disabled) {
      let newState: ICabinClassState = { ...cabinClasses };
      newState.First = !newState.First;
      props.UpdateCabinClasses(newState);
    }
  }

  const returnTabId = (segId: any, InputTab: any): any => {
    switch (segId) {
      case 1:
        if (InputTab == 1)
          return 5
        if (InputTab == 2)
          return 6
        if (InputTab == 3)
          return 7
        if (InputTab == 4)
          return 8
        break;
      case 2:
        if (InputTab == 1)
          return 8
        if (InputTab == 2)
          return 9
        if (InputTab == 3)
          return 10
        if (InputTab == 4)
          return 11
        break;
      case 3:
        if (InputTab == 1)
          return 11
        if (InputTab == 2)
          return 12
        if (InputTab == 3)
          return 13
        if (InputTab == 4)
          return 14
        break;
      default:
        return
    }
  }

  return (
    <React.Fragment> 
      <div className={`${props.ClassName}`}>
        <div style={{ flexWrap: 'wrap', gap: "4px" }} className="btn-group btn-group-sm " role="group">
          {cabinClass.Economy.Checked &&
            <div style={{ background: "white", borderRadius: "6px", margin: "1px" }}>
              <input disabled={props.disabled} type="checkbox" onChange={OnToggleEconomy} checked={cabinClasses.Economy} className="btn-check" tabIndex={returnTabId(props.segmentIndex, 1)} />
              <label onClick={OnToggleEconomy} className="btn btn-outline-primary">Economy</label>
            </div>
          }
          {cabinClass.Premium.Checked &&
            <div style={{ background: "white", borderRadius: "6px", margin: "1px" }}>
              <input disabled={props.disabled} type="checkbox" onChange={OnTogglePremiumEconomy} checked={cabinClasses.PremiumEconomy} className="btn-check" tabIndex={returnTabId(props.segmentIndex, 2)} />
              <label onClick={OnTogglePremiumEconomy} className="btn btn-outline-primary">Premium Economy</label>
            </div>}

          {cabinClass.Business.Checked &&
            <div style={{ background: "white", borderRadius: "6px", margin: "1px" }}>
              <input disabled={props.disabled} type="checkbox" onChange={OnToggleBusiness} checked={cabinClasses.Business} className="btn-check" tabIndex={returnTabId(props.segmentIndex, 3)} />
              <label onClick={OnToggleBusiness} className="btn btn-outline-primary">Business</label>
            </div>}

          {cabinClass.First.Checked &&
            <div style={{ background: "white", borderRadius: "6px", margin: "1px" }}>
              <input disabled={props.disabled} type="checkbox" onChange={OnToggleFirst} checked={cabinClasses.First} className="btn-check" tabIndex={returnTabId(props.segmentIndex, 4)} />
              <label onClick={OnToggleFirst} className="btn btn-outline-primary">First</label>
            </div>}

          {/* <label className={`btn overflow-ellipsis ${props.disabled ? "disabled" : ""} ${cabinClasses.Economy ? "active" : ""}`} onClick={OnToggleEconomy}>
            <span className={``}>Economy</span>
          </label>
          <label className={`btn overflow-ellipsis ${props.disabled ? "disabled" : ""} ${cabinClasses.PremiumEconomy ? "active" : ""}`} onClick={OnTogglePremiumEconomy}>
            <span className={``}>Premium Economy</span>
          </label>
          <label className={`btn overflow-ellipsis ${props.disabled ? "disabled" : ""} ${cabinClasses.Business ? "active" : ""}`} onClick={OnToggleBusiness}>
            <span className={``}>Business</span>
          </label>
          <label className={`btn overflow-ellipsis ${props.disabled ? "disabled" : ""} ${cabinClasses.First ? "active" : ""}`} onClick={OnToggleFirst}>
            <span className={``}>First</span>
          </label> */}
        </div>
      </div>
    </React.Fragment>
  );
};





export default CabinClassInput