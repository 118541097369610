import { faPlusCircle, faSync } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { BookingIdentifier, BookingItemModel, ENotificationActionRequiredEnumModel, ENotificationCategoryEnumModel, NotificationDataModel, UpdateNotificationModel } from "WebApiClient";
import { RenderNotification } from "./Notification";
import { NotificationsFilterInput } from "./NotificationFilter";
import { FilterNotificationState } from "./Types";
import { UpdateNotificationComponent } from "./UpdateNotification";
interface BookingNotificationsProps {
    Booking: BookingItemModel,
    OnUpdate: (data: BookingItemModel) => void,
    OnFetch: () => void,
    fetching: boolean,
    newNotification: UpdateNotificationModel | undefined,
    errorOccured: boolean,

}
export const BookingNotifications: React.FC<BookingNotificationsProps> = props => {
    const booking = props.Booking;
    const [newNotification, setNewNotification] = useState<UpdateNotificationModel | undefined>(undefined);
    const [filterState, setFilterState] = useState<FilterNotificationState>({
        ActionRequired: [ENotificationActionRequiredEnumModel.Urgent, ENotificationActionRequiredEnumModel.None, ENotificationActionRequiredEnumModel.Medium, ENotificationActionRequiredEnumModel.Low, ENotificationActionRequiredEnumModel.High],
        Descending: true, SortBy: "Priority",
        Category: [ENotificationCategoryEnumModel.BookingFailed, ENotificationCategoryEnumModel.OCN, ENotificationCategoryEnumModel.Other, ENotificationCategoryEnumModel.PaymentRejected, ENotificationCategoryEnumModel.QueueRobot, ENotificationCategoryEnumModel.Remark, ENotificationCategoryEnumModel.Reprice, ENotificationCategoryEnumModel.TTL]
    });
    const identifier: BookingIdentifier = booking.latestVersion?.bookingIdentifier;
    function GetEmptyNotification() {
        setNewNotification({ actionRequired: ENotificationActionRequiredEnumModel.None, branch: "", branchgroup: "", category: ENotificationCategoryEnumModel.Other, confidential: false, identifier: identifier, notificationID: undefined, readOnly: false, text: "", title: "", username: "" })
    }

    const notifications = props.Booking.latestVersion.notifications;
    const filteredNotifications = FilterAndSortNotifications(notifications, filterState);

    return (
        <div className="row">
            <React.Fragment>
                <div className="col-12 mb-2">
                    <div className="d-grid">
                        <button className="btn btn-secondary" onClick={props.OnFetch}><FontAwesomeIcon icon={faSync} /> Refresh</button>

                    </div>

                </div>

                {newNotification ?

                    <div className="col-12 mb-2">
                        <UpdateNotificationComponent OnConfirm={props.OnFetch} OnUpdate={(e) => { setNewNotification(e) }} notification={newNotification} OnCancel={() => { setNewNotification(undefined) }} />
                    </div>
                    :
                    <Fragment>
                        <div className="col-12 mb-2">
                            <NotificationsFilterInput data={filterState} OnUpdate={(e) => { setFilterState(e) }} />
                        </div>

                        <div className="col-12 mb-2">
                            <div className="d-grid">
                                <button className="btn btn-success" onClick={GetEmptyNotification}><FontAwesomeIcon icon={faPlusCircle} /> Add new notification</button>
                            </div>
                        </div>

                        <div className="col-12 mb-1">
                            <div className="card">
                                <div className="card-header card-header-primary">Showing {filteredNotifications.length} / {notifications.length} notifications</div>
                                <div className="card-body card-body-primary">
                                    <div className="row">
                                        {props.fetching &&
                                            <div className="col-12">
                                                <WaitingCard />
                                            </div>
                                        }
                                        {!props.fetching &&
                                            <Fragment>

                                                {notifications.length === 0 &&
                                                    <div className="col-12">
                                                        <Alert color="info">
                                                            No notifications for this booking.
                                                        </Alert>
                                                    </div>
                                                }
                                                {filteredNotifications.map((noti, index) =>
                                                    <div className="col-12 mb-2" key={"filteredNotifications" + index}>
                                                        <RenderNotification notification={noti} OnRefresh={props.OnFetch} BookingIdentifier={identifier} />
                                                    </div>
                                                )}
                                            </Fragment>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Fragment>
                }







            </React.Fragment>

        </div>
    );


}

function FilterAndSortNotifications(input: NotificationDataModel[], filter: FilterNotificationState): NotificationDataModel[] {
    let output = [...input];
    output = output.filter(e => (filter.ActionRequired.includes(e.actionRequired) && filter.Category.includes(e.category)));
    switch (filter.SortBy) {
        case "Date":
            output.sort((x, y) => {
                if (x.timeStamp !== undefined && y.timeStamp !== undefined) {
                    let res = x.timeStamp > y.timeStamp ? 1 : -1;
                    return res;
                };
                return 0;
            });
            break;
        case "Priority":
            output.sort((x, y) => (x.actionRequired as number) > (y.actionRequired as number) ? 1 : -1);
            break;
    }
    if (filter.Descending) {
        output.reverse();
    }
    return output;
}