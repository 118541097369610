import { GetRemarkLabel } from "components/Shared/RemarkEnumDisplay";
import React, { useEffect } from "react";
import { EnhancedRemarkModel } from "WebApiClient";

export const PnrEnhancedRemarksCard: React.FC<{ data: EnhancedRemarkModel[] ,ToggleElementsModal:()=>void,setTabIndexToggle?:any;}> = ({ data,ToggleElementsModal,setTabIndexToggle }) => {
    

    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Enhanced Remarks <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <div className="">
                    <table className="table table-sm table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th className="font-weight-bold">Remark</th>
                                <th className="font-weight-bold">Type</th>
                                <th className="font-weight-bold">Code</th>
                                <th className="font-weight-bold">Segments</th>
                                <th className="font-weight-bold">Passengers</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((remark, index) =>
                                <RemarkItem data={remark} key={"rmrk_" + index} />
                            )}
                        </tbody>
                    </table>
                </div>
                <div>
                    <button className="btn btn-primary" onClick={()=> {ToggleElementsModal();setTabIndexToggle(2)}}>Manage elements</button>
                </div>
            </div>
        </div>
    );


}

export const RemarkItem: React.FC<{ data: EnhancedRemarkModel }> = ({ data }) => {
    const { passengerSelection, remark, segmentSelection, remarkType, typeCode } = data;
    function processString(s: string): string {
        const elements = s.split(' ');
        const uniqueValues = elements.reduce((acc, val, idx, arr) => {
            if (idx % 2 === 0 && val === arr[idx + 1]) {
                acc.push(val);
            }
            return acc;
        }, [] as string[]);

        // If no unique values found, return the original string
        return uniqueValues.length > 0 ? uniqueValues.join(' ') : s;
    }

    return (
        <tr>
            {/* <td>{remark}</td> */}
            <td>{/(CTCM|CTCE)/.test(processString(remark))
                ? processString(remark)
                    .replace(/(CTCM|CTCE)\d+/g, '$1')   // Apply replacement for CTCM/CTCE
                    .replace(/-\d+/, '')                // Remove hyphen and digits
                : processString(remark)                 // Return original value if no CTCM/CTCE
            }</td>
            <td>{GetRemarkLabel(remarkType)}</td>
            <td>{typeCode}</td>
            <td>{
                segmentSelection.join(',')}
            </td>
            <td>{passengerSelection.length ? "P" + passengerSelection.join(',') : ""}</td>
        </tr>
    );


}


