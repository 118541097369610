import { BookingNotifications } from "components/BookingNotifications/BookingNotifications";
import { useBookingClient } from "hooks/useHttpClient";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import { BaseApiResponse, BookingItemModel, UpdateNotificationModel } from "WebApiClient";

interface BookingManagerManageNotificationModalProps {
  Booking: BookingItemModel;
  Toggle: () => void;
  IsOpen: boolean;
  OnUpdate: (data: BookingItemModel) => void;
}

export const BookingManagerManageNotificationModal: React.FC<
  BookingManagerManageNotificationModalProps
> = (props) => {
  const bookClient = useBookingClient();
  const [errorOccured, setErrorOccured] = useState(false);
  const [response, setResponse] = useState<BaseApiResponse | undefined>(undefined);
  const [fetching, setFetching] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [newNotification, setNewNotification] = useState<UpdateNotificationModel | undefined>(undefined);
  const dispatch = useDispatch();

  async function OnDeleteNotification() {
    try {
      setFetching(true);
      const { notifications, bookingIdentifier } = props.Booking.latestVersion;
      for (const notification of notifications) {
        try {
          const response = await bookClient.deleteNotification({
            identifier: bookingIdentifier,
            notificationID: notification.notificationID,
          });

          // Dispatch transaction to the store
          dispatch(Session_Reducer_PushTransaction(response.responseMetaData));

          // Update the status message and error status based on the response
          if (response.responseMetaData.errorOccured) {
            setStatusMessage("Notification could not be deleted!");
            setErrorOccured(true);
          } else {
            setStatusMessage("Notification deleted.");
            setErrorOccured(false);
          }
        } catch (error) {
          // Handle individual notification deletion errors
          setErrorOccured(true);
          console.error(`Failed to delete notification with ID: ${notification.notificationID}`, error);
        }
      }
    } catch (globalError) {
      console.error("Error occurred during notification deletion process:", globalError);
      setErrorOccured(true);
      OnFetch()
    } finally {
      setFetching(false);
      OnFetch()
    }
  }

  function OnFetch() {
    setFetching(true);
    setNewNotification(undefined);
    bookClient.getBookingDetails(props.Booking.latestVersion.bookingIdentifier)
      .then(response => {
        if (response.result) {
          props.OnUpdate(response.result)
        }
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        if (!response.result) {
          setErrorOccured(true)
        }
        else {
          setErrorOccured(false);
        }

      })
      .catch(() => {
        setErrorOccured(true);
      })
      .finally(() => {
        setFetching(false);
      })
  }

  return (
    <Modal
      size="xl"
      centered
      toggle={props.Toggle}
      isOpen={props.IsOpen}
      scrollable={true}>
      <ModalHeader toggle={props.Toggle}>
        Manage notifications for{" "}
        <strong> {props.Booking.latestVersion.generalInfo.filekey}</strong>
      </ModalHeader>

      <ModalBody>
        <BookingNotifications
          Booking={props.Booking}
          OnUpdate={props.OnUpdate}
          OnFetch={OnFetch}
          fetching={fetching}
          errorOccured={errorOccured}
          newNotification={newNotification}
        />
      </ModalBody>

      <ModalFooter className="justify-space-between">
        <Button color="primary" onClick={OnDeleteNotification}>
          Close all notifications
        </Button>
        <Button color="primary" onClick={props.Toggle}>
          Close
        </Button>
      </ModalFooter>

    </Modal>
  );
};

export default BookingManagerManageNotificationModal;
