import { BookingMangerPassengersTableCell } from "components/Shared/Helpers/BookingManagement/BookingPassengerHelpers";
import { BookingMangerSegmentsTableCell } from "components/Shared/Helpers/BookingManagement/BookingSegmentsHelpers";
import React, { useEffect } from "react";
import { BookingItemPassengerModel, PnrResponsePassengerModel, PnrResponseSegmentModel, PnrResponseSSRModel } from "WebApiClient";

interface SSRCardProps {
    data: PnrResponseSSRModel[],
    pnrPassengers?: PnrResponsePassengerModel[],
    bookingPassengers?: BookingItemPassengerModel[],
    segments: PnrResponseSegmentModel[],
    ToggleElementsModal:()=>void
    setTabIndexToggle?:any;
}
export const PnrSSRsCard: React.FC<SSRCardProps> = props => {


    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">SSR <span className="badge badge-primary badge-pill align-top">{props.data.length}</span></div>
            <div className="card-body">
                <SSRTable {...props} />
            </div>
        </div>
    );


}


export const SSRTable: React.FC<SSRCardProps> = ({ data, bookingPassengers, pnrPassengers, segments, ToggleElementsModal,setTabIndexToggle }) => {

    return  <>
    <table className="table table-sm table-striped table-hover table-bordered">
        <thead>
            <tr>
                <th className="font-weight-bold">Code</th>
                <th className="font-weight-bold">Text</th>
                <th className="font-weight-bold">Chargeable</th>
                <th className="font-weight-bold">Segments</th>
                <th className="font-weight-bold">Passengers</th>
                <th className="font-weight-bold">Status</th>
            </tr>

        </thead>
        <tbody>
            {data.map((service, index) =>
                <tr key={"serviceI_"+index}>
                    <td>{service.code}</td>
                    <td>{service.text}</td>
                    <td>{service.chargeable ? "Yes" : "No"}</td>
                    <BookingMangerSegmentsTableCell numbers={service.segmentNumbers} segments={segments} />
                    <BookingMangerPassengersTableCell numbers={service.passengerNumbers} bookingItemPassengers={bookingPassengers} pnrPassengers={pnrPassengers} />
                    <td>{service.status}</td>

                </tr>
            )}
        </tbody>
    </table>
        <div>
            <button className="btn btn-primary" onClick={()=> {ToggleElementsModal();setTabIndexToggle(0)}}>Manage elements</button>
        </div>
        </>
}




