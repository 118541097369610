import {
  faRotateExclamation,
  faSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateDisplay } from "components/Shared/DateDisplay";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import {
  AgentPlusBookingManagerFeature,
  BaseApiResponse,
  BookingItemModel,
  ENotificationActionRequiredEnumModel,
  UpdatedBookingApiResponse,
} from "WebApiClient";
import BookingDetailsModal from "./BookingManager.BookingDetailsModal";
import { BookingItemCard } from "./BookingManager.ItemCard";
import BookingMangerTableRowActions from "./BookingManager.Table.Row.Actions";
import { BookingStatusLabel } from "./BookingStatusLabel";
import BookingManagerErrorModal from "./SyncBookingModal";
import BookingMangerSeatmapModal from "./SeatmapModal";
import BookingMangerTableRowConfirmCancelModal from "./BookingManager.Table.Row.Actions.ConfirmCancel";
import BookingMangerTableRowConfirmCancelTicketModal from "./BookingManager.Table.Row.Actions.ConfirmCancelTicket";
import ConfirmTicketModal from "./ConfirmTicket";
import BookingMangerTableRowReshopModal from "./BookingManager.Table.Row.Actions.ReshopModal";
import BookingMangerManageServicesModal from "./BookingManager.ManageServicesModal";
import BookingMangerManageElementsModal from "./BookingManager.ManageElementsModal";
import BookingManagerManageNotificationModal from "./BookingManager.ManageNotificationsModal";
import ConfirmDetailsendModal from "./ConfirmDetailsendModal";
import FareRuleModal from "components/Fare/FareRulesModal";
import BookingManagerFareRuleModal from "./BookingManager.FareRuleModal";
import BookingManagerBookingCommunication from "./BookingManager.BookingCommunication";

interface BookingMangerTableRowProps {
  Booking: BookingItemModel;
  UpdateBooking: (data: BookingItemModel) => void;
  pageLength: number;
  Fetching: boolean;
  CardView: boolean;
  IndexRow?: any;
  showBool?: any;
  bool?: boolean;
  bookingIdentifier10: BookingItemModel[];
  errorIdentifier10: BookingItemModel[];
  bookingResponse10: UpdatedBookingApiResponse[]
}

type RowFetchingState = {
  bookingId: string;
  isFetching: boolean;
};

const BookingMangerTableRow: React.FC<BookingMangerTableRowProps> = (props) => {
  const booking = props.Booking;
  const [notificationsModalOpen, setNotificationsModalOpen] = useState(false);
  function ToggleNotificationsModal() {
    setNotificationsModalOpen(!notificationsModalOpen);
  }
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [cancelModalOpen, setcancelModalOpen] = useState(false);
  const [cancelTicketModalOpen, setcancelTicketModalOpen] = useState(false);
  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const [reshopModalOpen, setReshopModalOpen] = useState(false);
  const [modifyModalOpen, setModifyModalOpen] = useState(false);
  const [seatmapModalOpen, setSeatmapModalOpen] = useState(false);
  const [serviceModalOpen, setServiceModalOpen] = useState(false);
  const [elementsModalOpen, setElementsModalOpen] = useState(false);
  const [elementsConfirmEmailSendModalOpen, setElementsConfirmEmailSendModalOpen] = useState(false);
  const [fareRuleModalOpen, setFareRuleModalOpen] = useState(false);
  const [bookingCommunication, setBookingCommunication] = useState(false);
  const [rowFetchingStates, setRowFetchingStates] = useState<RowFetchingState[]>([]);
  const [rowFetching, setRowIsFetching] = useState(false);

  const [tabIndexToggle, setTabIndexToggle] = useState<number|undefined>(0)

  function ToggleDetailsModal() {
    setDetailsModalOpen(!detailsModalOpen);
    setTabIndexToggle(undefined)
  }
  function ToggleSeatmapModal() {
    setSeatmapModalOpen(!seatmapModalOpen);
  }
  function ToggleCancelModal() {
    setcancelModalOpen(!cancelModalOpen);
  }
  function ToggleCancelTicketModal() {
    setcancelTicketModalOpen(!cancelTicketModalOpen);
  }
  function ToggleTicketModal() {
    setTicketModalOpen(!ticketModalOpen);
  }
  function ToggleReshopModal() {
    setReshopModalOpen(!reshopModalOpen);
  }
  function ToggleModifyModal() {
    setModifyModalOpen(!modifyModalOpen);
  }
  function ToggleServiceModal() {
    setServiceModalOpen(!serviceModalOpen);
  }
  function ToggleElementsModal() {
    setElementsModalOpen(!elementsModalOpen);
  }
  function ToggleConfirmEmailSendModal() {
    setElementsConfirmEmailSendModalOpen(!elementsConfirmEmailSendModalOpen);
  }
  function ToggleFareRuleModal() {
    setFareRuleModalOpen(!fareRuleModalOpen);
  }
  function ToggleBookingCommunicationModal() {
    setBookingCommunication(!bookingCommunication);
  }

  const bookClient = useBookingClient();
  const [syncResponse, setSyncResponse] = useState<BaseApiResponse | undefined>(undefined);
  const dispatch = useDispatch();
  const [syncErrorOccured, setSyncErrorOccured] = useState(false);
  const isFetching = props.Fetching || rowFetching;

  const checkIfBookingExists = (pnr: BookingItemModel) => {
    return props.bookingIdentifier10.some(
      (identifier) => identifier.latestVersion.bookingIdentifier.bookingId === pnr.latestVersion.bookingIdentifier.bookingId
    );
  };

  const checkIfResponseExists = (pnr: UpdatedBookingApiResponse) => {
    let errorOccurred = false;
    const responseExists = props.bookingResponse10.some((response) => {
      // Check if there's an error, and set errorOccurred accordingly
      if (response?.responseMetaData?.errorOccured) {
        errorOccurred = true;
        return false; // Continue to the next iteration
      }
      // Check if the booking data exists and matches the provided booking
      return (
        response?.updatedBooking?.latestVersion?.bookingIdentifier?.bookingId !== undefined &&
        pnr?.updatedBooking?.latestVersion?.bookingIdentifier?.bookingId !== undefined &&
        response.updatedBooking.latestVersion.bookingIdentifier.bookingId ===
        pnr.updatedBooking.latestVersion.bookingIdentifier.bookingId
      );
    });

    return errorOccurred ? false : responseExists;
  };

  const updateRowFetchingState = (bookingId: string, isFetching: boolean) => {
    setRowFetchingStates((prevState) => ({
      ...prevState,
      bookingId: bookingId,
    }));

    if (bookingId === booking.latestVersion.bookingIdentifier.bookingId) {
      console.log("hiii", bookingId);
      setRowIsFetching(isFetching);
    }
  };

  useEffect(() => {
    props.bookingIdentifier10.forEach((pnr) => {
      const isBookingFound = checkIfBookingExists(pnr);
      if (isBookingFound) {
        updateRowFetchingState(pnr.latestVersion.bookingIdentifier.bookingId, true);
      }
    });
  }, [props.bookingIdentifier10.length]);

  useEffect(() => {
    props.bookingResponse10.forEach((pnr: UpdatedBookingApiResponse) => {
      const isResponseAvailable = checkIfResponseExists(pnr);
      if (isResponseAvailable) {
        updateRowFetchingState(pnr.updatedBooking.latestVersion.bookingIdentifier.bookingId, false);
      }
    });
  }, [props.bookingResponse10.length]);

  useEffect(() => {
    if (props.errorIdentifier10.length > 0) {
      props.errorIdentifier10.forEach((pnr: BookingItemModel) => {

        if (pnr?.latestVersion?.bookingIdentifier.bookingId === booking.latestVersion.bookingIdentifier.bookingId) {
          // Update the fetching state
          setRowIsFetching(false);
        }

      });
    }
  }, [props.errorIdentifier10]);

  const OnSync = () => {
    setRowIsFetching(true);
    bookClient.syncBooking(booking.latestVersion.bookingIdentifier)
      .then((response) => {
        setSyncResponse(response);
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        if (response.responseMetaData.errorOccured) {
          setSyncErrorOccured(true);
        } else {
          setSyncErrorOccured(false);
          props.UpdateBooking(response.updatedBooking!);
        }
      })
      .catch(() => {
        setSyncErrorOccured(true);
      })
      .finally(() => {
        setRowIsFetching(false);
      });
  };

  return (
    <React.Fragment>
      {props.CardView ? (
        <Fragment>
          <BookingItemCard
            UpdateBooking={props.UpdateBooking}
            item={props.Booking}
            NotificationsModalOpen={notificationsModalOpen}
            ToggleNotificationModal={ToggleNotificationsModal}
            ToggleDetailsModal={ToggleDetailsModal}
            OnSync={OnSync}
            IsFetching={isFetching}
            setMapToggle={ToggleSeatmapModal}
            seatmapModalOpen={seatmapModalOpen}
            serviceModalOpen={serviceModalOpen}
            serviceToggle={ToggleServiceModal}
            cancelModalOpen={cancelModalOpen}
            cancelTicketModal={ToggleCancelTicketModal}
            cancelModal={ToggleCancelModal}
            ElementsModal={ToggleElementsModal}
            ConfirmEmailSend={ToggleConfirmEmailSendModal}
            EmailSendModalOpen={elementsConfirmEmailSendModalOpen}
            ticketModal={ToggleTicketModal}
            ToggleFareModal={ToggleFareRuleModal}
            ToggleBookingCommunicationModal={ToggleBookingCommunicationModal}
          />
        </Fragment>
      ) : (
        <Fragment>
          {isFetching ? (
            <Fragment>
              <EmptyBookingMangerTableRow></EmptyBookingMangerTableRow>
            </Fragment>
          ) : (
            <Fragment>
              <BookingMangerTableRowData
                Booking={props.Booking}
                ToggleNotificationsModal={ToggleNotificationsModal}
                ToggleDetailsModal={ToggleDetailsModal}
                OnSync={OnSync}
                IndexRow={props.IndexRow}
                showBool={props.showBool}
                bool={props.bool}
              />
              <td>
                <BookingMangerTableRowActions
                  Booking={props.Booking}
                  OnUpdateBooking={props.UpdateBooking}
                  NotificationsModalOpen={notificationsModalOpen}
                  ToggleNotificationModal={ToggleNotificationsModal}
                  ToggleDetailsModal={ToggleDetailsModal}
                  OnSync={OnSync}
                  IsFetching={isFetching}
                  setMapToggle={ToggleSeatmapModal}
                  seatmapModalOpen={seatmapModalOpen}
                  serviceModalOpen={serviceModalOpen}
                  serviceToggle={ToggleServiceModal}
                  cancelModalOpen={cancelModalOpen}
                  cancelTicketModal={ToggleCancelTicketModal}
                  cancelModal={ToggleCancelModal}
                  ElementsModal={ToggleElementsModal}
                  ticketModal={ToggleTicketModal}
                  ConfirmEmailSend={ToggleConfirmEmailSendModal}
                  EmailSendModalOpen={elementsConfirmEmailSendModalOpen}
                  ToggleFareModal={ToggleFareRuleModal}
                  ToggleBookingCommunicationModal={ToggleBookingCommunicationModal}
                />
              </td>
              {/* ACTIONS */}
            </Fragment>
          )}
        </Fragment>
      )}

      <BookingMangerTableRowConfirmCancelModal
        Booking={props.Booking}
        OnUpdate={props.UpdateBooking}
        Toggle={ToggleCancelModal}
        IsOpen={cancelModalOpen}
      />

      <BookingMangerTableRowConfirmCancelTicketModal
        Booking={props.Booking}
        OnUpdate={props.UpdateBooking}
        Toggle={ToggleCancelTicketModal}
        IsOpen={cancelTicketModalOpen}
      />

      <ConfirmTicketModal
        Booking={props.Booking}
        OnUpdate={props.UpdateBooking}
        Toggle={ToggleTicketModal}
        IsOpen={ticketModalOpen}
        OnSync={OnSync}
      />
      <BookingMangerTableRowReshopModal
        Booking={props.Booking}
        Toggle={ToggleReshopModal}
        IsOpen={reshopModalOpen}
      />
      <BookingMangerManageServicesModal
        Booking={props.Booking}
        Toggle={ToggleServiceModal}
        IsOpen={serviceModalOpen}
      />
      <BookingMangerSeatmapModal
        Booking={props.Booking}
        Toggle={ToggleSeatmapModal}
        IsOpen={seatmapModalOpen}
      />
      <BookingMangerManageElementsModal
        Booking={props.Booking}
        Toggle={ToggleElementsModal}
        IsOpen={elementsModalOpen}
        tabIndexToggle={tabIndexToggle}
      />
      <BookingManagerManageNotificationModal
        Booking={props.Booking}
        OnUpdate={props.UpdateBooking}
        Toggle={ToggleNotificationsModal}
        IsOpen={notificationsModalOpen}
      />
      <BookingDetailsModal
        Booking={props.Booking}
        OnUpdate={props.UpdateBooking}
        Toggle={ToggleDetailsModal}
        IsOpen={detailsModalOpen}
        OnSync={OnSync}
        ToggleElementsModal={ToggleElementsModal}
        setTabIndexToggle={setTabIndexToggle}
      />
      <BookingManagerErrorModal
        Close={() => {
          setSyncResponse(undefined);
          setSyncErrorOccured(false);
        }}
        IsOpen={syncErrorOccured}
        data={syncResponse?.responseMetaData}
      />

      <ConfirmDetailsendModal
        Booking={props.Booking}
        Toggle={ToggleConfirmEmailSendModal}
        IsOpen={elementsConfirmEmailSendModalOpen}
        IsPopUp={false}
      />

      <BookingManagerFareRuleModal
        Booking={props.Booking}
        OnUpdate={props.UpdateBooking}
        Toggle={ToggleFareRuleModal}
        IsOpen={fareRuleModalOpen}
        OnSync={OnSync} />

      <BookingManagerBookingCommunication
        Booking={props.Booking}
        OnUpdate={props.UpdateBooking}
        Toggle={ToggleBookingCommunicationModal}
        IsOpen={bookingCommunication}
        OnSync={OnSync} />

    </React.Fragment>
  );
};

interface BookingTableRowData {
  Booking: BookingItemModel;
  ToggleNotificationsModal?: () => void;
  ToggleDetailsModal?: () => void;
  OnSync?: () => void;
  IndexRow?: any;
  bool?: boolean;
  showBool?: any;
}

export const BookingMangerTableRowData: React.FC<BookingTableRowData> = (
  props
) => {
  const booking = props.Booking;
  const indexNo = props.IndexRow;
  const latestVersion = booking.latestVersion;
  const generalInfo = booking.latestVersion.generalInfo;
  const passenger = booking.latestVersion.flightDetailsInfo?.passengers;
  const notifications = latestVersion.notifications;
  const noPrioNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.None
  ).length;
  const urgentNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.Urgent
  ).length;
  const highPrioNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.High
  ).length;
  const mediumPrioNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.Medium
  ).length;
  const lowPrioNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.Low
  ).length;
  const headerClassName = props.ToggleDetailsModal ? "clickme" : "";
  const notiClassName = props.ToggleNotificationsModal ? "clickme" : "";
  const actions = latestVersion.allowedActions;

  useEffect(() => {
    if (
      generalInfo.platingCarrier.code === "" &&
      indexNo === 0 &&
      !props.bool
    ) {
      // props.OnSync;
      props.showBool(true);
    }
    // GetBookingDetailsByFileKey(Indentifyers);
  }, []);


  return (
    <React.Fragment>
      {props.OnSync && (
        <Fragment>
          {actions.includes(AgentPlusBookingManagerFeature.RefreshPnr) ? (
            <td className={headerClassName} onClick={props.OnSync}>
              <FontAwesomeIcon title="Refresh PNR" icon={faRotateExclamation} />
            </td>
          ) : (
            <td className={headerClassName}></td>
          )}
        </Fragment>
      )}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        <div className="d-flex justify-content-between align-items-center">
          <span>{generalInfo.mainPassengerLastName}</span>
          {passenger?.length! > 1 && <div className="p-1 circle">{passenger?.length}</div>}
        </div>
      </td>
      {/* LAST NAME */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        {generalInfo.filekey}
      </td>
      {/* FILEKEY */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        {generalInfo.platingCarrier.code}
      </td>
      {/* AIRLINE */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        {generalInfo.crs?.gds}
      </td>
      {/* SOURCEGDS */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        {generalInfo.crs?.terminalPCC}
      </td>
      {/* SOURCETERMINALPCC */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        <BookingStatusLabel Status={generalInfo.bookingStatus} />
      </td>
      {/* STATUS BOOKING */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        <DateDisplay date={generalInfo.bookingDate} />
      </td>
      {/* DATE BOOKING */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        <DateDisplay date={generalInfo.firstDepartureDate} />
      </td>
      {/* FIRDSTDEPARTURE */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        <DateDisplay date={generalInfo.ticketTimeLimit} />
      </td>
      {/* TTL */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        <DateDisplay date={generalInfo.ticketingDate} />
      </td>
      {/* TICKETING DATE */}
      <td
        className={`text-center ${notiClassName} ${urgentNotificationsAmount > 0 ? "dangerBg" : ""
          }`}
        onClick={props.ToggleNotificationsModal}
      >
        {urgentNotificationsAmount > 0 ? (
          <span className="text-white">{urgentNotificationsAmount}</span>
        ) : (
          <Fragment>
            {highPrioNotificationsAmount > 0 && (
              <span className="badge rounded-pill bg-danger font-weight-bold text-white">
                {highPrioNotificationsAmount}
                {` `}
              </span>
            )}
            {mediumPrioNotificationsAmount > 0 && (
              <span className="badge rounded-pill bg-warning font-weight-bold">
                {mediumPrioNotificationsAmount}
                {` `}
              </span>
            )}
            {lowPrioNotificationsAmount > 0 && (
              <span className="badge rounded-pill bg-success font-weight-bold text-white">
                {lowPrioNotificationsAmount}
              </span>
            )}
            {noPrioNotificationsAmount > 0 && (
              <span
                style={{ backgroundColor: "gray" }}
                className="badge rounded-pill font-weight-bold"
              >
                {noPrioNotificationsAmount}
              </span>
            )}
          </Fragment>
        )}
      </td>
    </React.Fragment>
  );
};

export const EmptyBookingMangerTableRow: React.FC = (props) => {
  return (
    <React.Fragment>
      <td align="center" colSpan={13}>
        <span className="btn btn-sm">
          <FontAwesomeIcon icon={faSpinner} spin /> Loading...
        </span>
      </td>
    </React.Fragment>
  );
};

export default BookingMangerTableRow;
