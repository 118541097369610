import {
    faSync,
    faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { BookingCommunicationResponse, BookingItemModel, IoResponse } from "WebApiClient";

interface BookingDetailsModalProps {
    Booking: BookingItemModel;
    Toggle: () => void;
    IsOpen: boolean;
    OnUpdate: (data: BookingItemModel) => void;
    OnSync: any;
}

const BookingManagerBookingCommunication: React.FC<BookingDetailsModalProps> = (props) => {
    const client = useBookingClient();
    const [response, setResponse] = useState<BookingCommunicationResponse | undefined>(undefined);
    const [error, setError] = useState(false);
    const [fetching, setFetching] = useState(false);


    useEffect(() => {
        if (props.IsOpen) {
            fetchBookingCommunication();
        }
    }, [props.IsOpen]);


    function fetchBookingCommunication() {
        setFetching(true);
        setResponse(undefined);
        setError(false);
        client
            .getBookingCommunication({
                bookingId: props.Booking.latestVersion.bookingIdentifier.bookingId,
            })
            .then((response) => {
                setResponse(response);
                if (response.result) {
                    setError(false);
                } else {
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setFetching(false);
            });
    }
    const result = response?.result;
    return (
        <>
            <Modal
                size="xl"
                centered
                toggle={props.Toggle}
                isOpen={props.IsOpen}
                scrollable
            >
                <ModalHeader toggle={props.Toggle}>
                    Booking Communication for{" "}
                    <span className="font-weight-bold">
                        {props.Booking.latestVersion.generalInfo.filekey}
                    </span>
                </ModalHeader>
                <ModalBody>
                    {props.IsOpen && (
                        <div className="row">
                            {fetching ? (
                                <div className="col-12 mb-2">
                                    <WaitingCard />
                                </div>
                            ) : (
                                <Fragment>
                                    {error && (
                                        <div className="col-12">
                                            <ErrorDisplay data={response?.responseMetaData} />
                                        </div>
                                    )}
                                    {result && (
                                        <div className="col-12 mb-2">
                                            <FareRuleDisplay IoResponse={result.ioResponsesResult} />
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => fetchBookingCommunication()}>
                        <FontAwesomeIcon icon={faSync} /> Refresh
                    </Button>
                    <Button color="primary" onClick={props.Toggle}>
                        <FontAwesomeIcon icon={faTimes} /> Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

const FareRuleDisplay: React.FC<{ IoResponse: IoResponse[] }> = (props) => {
    console.log("data-------", props.IoResponse);

    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <Table responsive striped bordered>
                            <tbody>
                                {props.IoResponse.map((response, responseIndex) => (
                                    <tr key={responseIndex}>
                                        <td className="font-weight-bold text-start">{response.bookingSource}</td>
                                        <td className="text-start">
                                            <pre style={{ marginBottom: "0px" }}>
                                                <strong>request:</strong>  {response.request}
                                            </pre>
                                            <pre style={{ marginBottom: "0px" }}>
                                                <strong>response:</strong>  {response.response}
                                            </pre>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingManagerBookingCommunication
