import {
    faSync,
    faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { BookingItemModel, FareRulesResponse, RuleTextResponseDataView } from "WebApiClient";

interface BookingDetailsModalProps {
    Booking: BookingItemModel;
    Toggle: () => void;
    IsOpen: boolean;
    OnUpdate: (data: BookingItemModel) => void;
    OnSync: any;
}
const BookingManagerFareRuleModal: React.FC<BookingDetailsModalProps> = (props) => {
    const client = useBookingClient();
    const [response, setResponse] = useState<FareRulesResponse | undefined>(undefined);
    const [error, setError] = useState(false);
    const [fetching, setFetching] = useState(false);


    useEffect(() => {
        if (props.IsOpen) {
            fetchFareRules();
        }
    }, [props.IsOpen]);

    function fetchFareRules() {
        setFetching(true);
        setResponse(undefined);
        setError(false);

        client
            .getFareRules({
                bookingId: props.Booking.latestVersion.bookingIdentifier.bookingId,
            })
            .then((response) => {
                setResponse(response);
                if (response.result) {
                    setError(false);
                } else {
                    setError(true);
                }
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setFetching(false);
            });
    }

    const result = response?.result?.fareRulesResult?.rules;

    return (
        <React.Fragment>
            <Modal
                size="xl"
                centered
                toggle={props.Toggle}
                isOpen={props.IsOpen}
                scrollable
            >
                <ModalHeader toggle={props.Toggle}>
                    Fare Rules for{" "}
                    <span className="font-weight-bold">
                        {props.Booking.latestVersion.generalInfo.filekey}
                    </span>
                </ModalHeader>
                <ModalBody>
                    {props.IsOpen && (
                        <div className="row">
                            {fetching ? (
                                <div className="col-12 mb-2">
                                    <WaitingCard />
                                </div>
                            ) : (
                                <Fragment>
                                    {error && (
                                        <div className="col-12">
                                            <ErrorDisplay data={response?.responseMetaData} />
                                        </div>
                                    )}
                                    {result && (
                                        <div className="col-12 mb-2">
                                            <FareRuleDisplay data={{ rules: result }} />
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => fetchFareRules()}>
                        <FontAwesomeIcon icon={faSync} /> Refresh
                    </Button>
                    <Button color="primary" onClick={props.Toggle}>
                        <FontAwesomeIcon icon={faTimes} /> Close
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

const FareRuleDisplay: React.FC<{ data: RuleTextResponseDataView }> = (props) => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <Table responsive striped bordered>
                            <tbody>
                                {props.data.rules.map((chapter, chapterIndex) => (
                                    <tr key={chapterIndex}>
                                        <td className="font-weight-bold text-start">{chapter.ruleHeader}</td>
                                        <td className="text-start">
                                            {chapter.ruleLines.map((line, lineIndex) =>
                                                line.length > 100 ? (
                                                    <p style={{ marginBottom: "0px" }} key={lineIndex}>
                                                        {line}
                                                    </p>
                                                ) : (
                                                    <pre style={{ marginBottom: "0px" }} key={lineIndex}>
                                                        {line}
                                                    </pre>
                                                )
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingManagerFareRuleModal;
