import { AvailableFares } from "components/AvailableFares/component/AvailableFares";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import { FareBookingShell } from "components/FareBooking/component/FareBooking";
import FareSearch from "components/FareSearch/component/FareSearch";
import { useSessionHandler } from "hooks/useSessionHandler";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { State } from "rootExports/rootReducer";
import {
    AvailableFareModel,
    FareSearchSubmitApiResponse,
    UpdateCustomerProfileDataRequest,
} from "WebApiClient";

export interface SetBookingPayload {
    Fare: AvailableFareModel;
    SelectedConnections: number[];
    AddedServices: ServiceAddObj[];
    OnSelectConnection: (legIndex: number, connectionIndex: number) => void;
}

const AvailableFaresPage: React.FC<{}> = (props) => {
    const DEBUG = true;
    const sessionHook = useSessionHandler();

    const [fareSortStores, setFareSortStores] = useState({BestFare:[],BestCarousel:[],cheapestCarousel:[],cheapestFare:[],fastFare:[],fastCarousel:[],shortFare:[],shortCarousel:[]})

    const [submitResult, setSubmitResult] = useState<
        FareSearchSubmitApiResponse | undefined
    >(undefined);
    const fareResult = submitResult?.result?.fareSearchResult;
    var originalName: any;
    if (
        submitResult &&
        submitResult.result &&
        submitResult.result.fareSearchResult
    ) {
        originalName = _.cloneDeep(submitResult.result.fareSearchResult);
        // Now you can use originalName as needed
    } else {
        // If any of the properties is undefined, use fareResult as a fallback
        originalName = _.cloneDeep(fareResult);
        // Now you can use originalName as needed
    }

    const [activeTab, setActiveTab] = useState("1");
    const [currentBooking, setCurrentBooking] = useState<
        SetBookingPayload | undefined
    >(undefined);
    const customerProfileData: UpdateCustomerProfileDataRequest | undefined =
        useSelector(
            (state: State) => state.Session.FrontendSettings.customerProfileData
        );

    const toggle = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    function OnSearchSubmit(response: FareSearchSubmitApiResponse) {
        setSubmitResult(undefined);
        setSubmitResult(response);
        setCurrentBooking(undefined);
        if (response.result?.fareSearchResult) {
            setActiveTab("2");
        }
        sessionHook.RefreshSession();
    }

    useEffect(() => {
        // dispatch(Session_Saga_RefreshToken());
        document.title = `Flights`;
        if (DEBUG) {
            console.log(`Available Fares Page initialized.`);
        }
        sessionHook.RefreshSession();
    }, []);

    function BookFare(payload: SetBookingPayload) {
        window.scrollTo(0, 0);
        setCurrentBooking(payload);
        setActiveTab("3");
    }

    function OnModify() {
        window.scrollTo(0, 0);
        setActiveTab("1");
    }

    function OnDiscard() {
        window.scrollTo(0, 0);
        setActiveTab("1");
        setSubmitResult(undefined);
        setCurrentBooking(undefined);
        // history.replace(`/availfares`)rrrrrrrrrrrr
    }

    // function AutoFocusPage() {
    //     const x = document.getElementById('screen-query');
    //     if (x) {
    //         x.focus()
    //     }
    // }
    const bookingRedirect = useSelector((state: any) => state.bookingRedirectSession.value);

    const convertToCode = (location: string) => {
        // Use regex to extract the code inside brackets
        return _.replace(location, /\[([A-Z]+)\].*/, '$1');
    };


    const [isDisable, setIsDisable] = useState<boolean>(false);


    useEffect(() => {
        if (bookingRedirect?.checkActiveBtnClicked) {
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    }, [])
   
    return (
        <div>
            <div>
                {customerProfileData && (
                    <Alert color="warning">
                        Customer profile data{" "}
                        {customerProfileData.title &&
                            customerProfileData.title.length > 0 && (
                                <span className="font-weight-bold">
                                    "{customerProfileData.title}"
                                </span>
                            )}{" "}
                        is active. Profile will remain active until logout.
                    </Alert>
                )}
                {bookingRedirect?.bookingRedirect && (
                    <div className="py-4 text-center"
                        style={{ backgroundColor: "#f016e2", color: '#fff' }}
                    >
                        <div>
                            {
                                bookingRedirect?.checkActiveBtnClicked == "Add" ? <>
                                    <div>You are about to add a new leg to your existing filekey <strong> {bookingRedirect?.recordLocator}</strong></div>
                                </> : <>
                                    {
                                        bookingRedirect?.bookingSegmentsData?.length == 1 ? <>
                                            <div><h2 className="mb-1"> Rebook flights in filekey <strong> {bookingRedirect?.recordLocator}</strong> </h2>
                                                <p className="mb-1"> from
                                                    <strong> {bookingRedirect?.bookingSegmentsData[0].departure.iata} </strong> to <strong> {bookingRedirect?.bookingSegmentsData[0].arrival.iata} </strong>  on the <strong> {moment(bookingRedirect?.bookingSegmentsData[0].departureDate).format('DD.MMM YYYY')}</strong></p>
                                                <p className="mb-0">
                                                    into <strong> {bookingRedirect?.bookingSegmentsDataCustom?.DepartureInput == "" ? bookingRedirect?.bookingSegmentsData[0].departure.iata : convertToCode(bookingRedirect?.bookingSegmentsDataCustom?.DepartureInput)} </strong> to <strong> {bookingRedirect?.bookingSegmentsDataCustom?.ArrivalInput == "" ? bookingRedirect?.bookingSegmentsData[0].arrival.iata : convertToCode(bookingRedirect?.bookingSegmentsDataCustom?.ArrivalInput)} </strong>  on the <strong> {moment(bookingRedirect?.bookingSegmentsDataCustom?.DateInput == undefined ? bookingRedirect?.bookingSegmentsData[0].departureDate : bookingRedirect?.bookingSegmentsDataCustom?.DateInput).format('DD.MMM YYYY')}</strong></p>
                                            </div>
                                        </> : <>
                                            <div><h2 className="mb-1"> Rebook flights in filekey <strong> {bookingRedirect?.recordLocator}</strong></h2>
                                                <p className="mb-1"> from <strong>{bookingRedirect?.bookingSegmentsData[0].departure.iata}</strong> to
                                                    <strong> {bookingRedirect?.bookingSegmentsData[bookingRedirect?.bookingSegmentsData?.length - 1].arrival.iata
                                                    } </strong> on the <strong> {moment(bookingRedirect?.bookingSegmentsData[0].departureDate).format('DD.MMM YYYY')}</strong>
                                                </p>
                                                <p className="mb-0">
                                                    into <strong>{bookingRedirect?.bookingSegmentsDataCustom?.DepartureInput == "" ? bookingRedirect?.bookingSegmentsData[0].departure.iata : convertToCode(bookingRedirect?.bookingSegmentsDataCustom?.DepartureInput)}</strong> to
                                                    <strong> {bookingRedirect?.bookingSegmentsDataCustom?.ArrivalInput == "" ? bookingRedirect?.bookingSegmentsData[bookingRedirect?.bookingSegmentsData?.length - 1].arrival.iata :
                                                        convertToCode(bookingRedirect?.bookingSegmentsDataCustom?.ArrivalInput)
                                                    } </strong> on the <strong> {moment(bookingRedirect?.bookingSegmentsDataCustom?.DateInput == undefined ? bookingRedirect?.bookingSegmentsData[0].departureDate : bookingRedirect?.bookingSegmentsDataCustom?.DateInput).format('DD.MMM YYYY')}</strong> </p>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                )}
                <div className="">
                    <div className={`${activeTab !== "1" ? "d-none" : ""}`}>
                        <FareSearch
                            OnReset={OnDiscard}
                            IsModifySearch={false}
                            OnSearchSubmitted={OnSearchSubmit}
                            LastSubmitted={fareResult?.submittedSearch}
                            submitResult={submitResult}
                            isDisable={isDisable}
                            GoToResults={
                                fareResult
                                    ? () => {
                                        setActiveTab("2");
                                    }
                                    : undefined
                            }
                        />
                    </div>
                    <div className={`${activeTab !== "2" ? "d-none" : ""}`}>
                        {fareResult && (
                            <AvailableFares
                                OriginalFare={originalName}
                                data={fareResult}
                                BookFare={BookFare}
                                OnDiscard={OnDiscard}
                                OnModify={OnModify}
                                IsActive={activeTab === "2"}
                                key={fareResult.fareResultID}
                                setFareSortStores={setFareSortStores}
                                fareSortStores={fareSortStores}
                            />
                        )}
                    </div>
                    <div className={`${activeTab !== "3" ? "d-none" : ""}`}>
                        <FareBookingShell
                            Intit={currentBooking}
                            multiChannel={fareResult?.multiChannelRuleInfo}
                            Discard={() => {
                                setCurrentBooking(undefined);
                            }}
                            OnNewQuery={OnDiscard}
                            GoToFares={() => {
                                setActiveTab("2");
                            }}
                            OnModify={OnModify}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AvailableFaresPage;
